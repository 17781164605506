import { ReactElement, useContext, useEffect, useState, useCallback, useRef } from "react";
import { ThemeContext } from "../../components/Theme/context";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import RefreshIcon from "@mui/icons-material/RefreshSharp";
import { InfoAlert } from "../component/infoAlert";
import { PerformanceSummaryTable } from "./performanceSummaryTable";
import { KeyTable } from "./keyTable";
import { Modal, Box, Typography, Button, Tooltip } from '@mui/material';
import { getTopPerformingCampaign, getUserDataByMail } from "../../utils/helper";
import axios from "axios";
import { FavoriteLaptopIcon, FBIcon, GoogleIcon, SendIcon } from "../component/icons";
import { getTopPerformingCampaignIndex, updateOrCreateTopCampaign } from "../../utils/helper";
import UserContext from "../../utils/userContext";
import { CampaignIdea, CampaignIdeasTable, isValidCampaignIdeas } from "./campaignIdeasTable";

export const Campaigns = () => {
  const themeContext = useContext(ThemeContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [campaignIndex, setCampaignIndex] = useState<number>(0);
  const [campaignData, setCampaignData] = useState<any>([]);
  const [insightData, setInsightData] = useState<any>("");
  const [topCampaign, setTopCampaign] = useState<any>({});
  const [topCompetitor, setTopCompetitor] = useState<any>();
  const [topCompetitorInsight, setTopCompetitorInsight] = useState<any>("");
  const [campaignIdeas, setCampaignIdeas] = useState<Array<CampaignIdea>>([]);
  const [campaignIdeasStatus, setCampaignIdeasStatus] = useState("Loading...");
  const { setloading } = useContext(UserContext);
  const initialized = useRef(false);
  const [perform, setPerform] = useState<Perform>({
    id: -1,
    name: '',
    impressions: 0,
    clicks: 0,
    ctr: 0,
    cpa: 0,
    conversions: 0,
    ai_score: 0
  });
  interface Perform {
    id: number;
    name: string;
    impressions: number;
    clicks: number;
    ctr: number;
    cpa: number;
    conversions: number;
    ai_score: number;
  }
  const access_token = localStorage.getItem('access_token');
  const customerId = localStorage.getItem("customerId")?.replace(/-/g, '');

  const fetchInsights = useCallback(async (campaign: Perform) => {
    console.warn("Fetch Insights...");
    setInsightData("Loading...");
    setloading(true);
    try {
      const mail = localStorage.getItem('email') ?? "";
      const userData = await getUserDataByMail(mail);
      if (userData) {
        const response = await axios.post(`${process.env.REACT_APP_SERVER}/campaigns/insights`, {
          user: userData,
          campaign,
        });
        setInsightData(response.data || "Not found");
        setloading(false);
      } else {
        setInsightData("Not found");
        setloading(false);
      }
    } catch (error) {
      console.warn("Error fetching insights:", error);
      setInsightData("Unexpected Error");
      setloading(false);
    }
  }, []);

  const fetchInsightsTopCompetitor = useCallback(async (
    campaign: Perform, 
    topCampaignCompetitor: Perform
  ) => {
    console.warn("Fetch Top Competitor Insights...");
    setTopCompetitorInsight("Loading...");
    setloading(true);
    try {
      const mail = localStorage.getItem('email') ?? "";
      const userData = await getUserDataByMail(mail);
      if (userData) {
        const response = await axios.post(`${process.env.REACT_APP_SERVER}/campaigns/top-competitor-insights`, {
          user: userData,
          topCampaign: campaign,
          topCampaignCompetitor,
        });
        setTopCompetitorInsight(response.data || "Not found");
        setloading(false);
      } else {
        setTopCompetitorInsight("Not found");
        setloading(false);
      }
    } catch (error) {
      console.warn("Error fetching insights:", error);
      setTopCompetitorInsight("Unexpected Error");
      setloading(false);
    }
  }, []);

  const fetchDailyCampaignIdeas = useCallback(async () => {
    console.warn("Fetch Daily Campaign Ideas...");
    setloading(true);
    setCampaignIdeasStatus("Loading...");
    const mail = localStorage.getItem('email') ?? "";
    const userData = await getUserDataByMail(mail);
    axios.post(`${process.env.REACT_APP_SERVER}/campaigns/daily-campaign-ideas`, {
      user: userData
    })
    .then(async (res) => {
      if (isValidCampaignIdeas(res.data)) {
        setCampaignIdeas(res.data);
        setCampaignIdeasStatus("");
      } else {
        setCampaignIdeasStatus("Unable to get campaign ideas. Try the 'Refresh Ideas' option.");
      }
      setloading(false);
    })
    .catch(() => {
      setloading(false);
      setCampaignIdeasStatus("No campaign ideas available at the moment. Try the 'Refresh Ideas' option.");
    });
  }, []);

  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true; 
    const initialize = async () => {
      try {
        setloading(true);
        const mail = localStorage.getItem('email') ?? "";
        const userData = await getUserDataByMail(mail);
        await axios.post(`${process.env.REACT_APP_SERVER}/campaigns`, {
          refresh_token: access_token,
          customerId
        }).then(async (res) => {
          setCampaignData(res.data?.perform);
          setPerform(res.data?.perform[campaignIndex]?.data[0]);
          console.log(res.data?.perform[campaignIndex]?.data[0]);
          await fetchInsights(res.data?.perform[campaignIndex]?.data[0]);
          setKeywords(res.data?.keywords[0]);
          const topCampaignIndex = getTopPerformingCampaignIndex(res.data?.perform, 'impressions');
          setTopCampaign(res.data?.perform[topCampaignIndex]?.data[0]);

          await updateOrCreateTopCampaign(res.data?.perform[topCampaignIndex]?.data[0], userData);
          const topCompetitorData = await getTopPerformingCampaign();

          setTopCompetitor(topCompetitorData?.campaignData);
         
          await fetchInsightsTopCompetitor(
            res.data?.perform[topCampaignIndex]?.data[0], 
            topCompetitorData?.campaignData
          );
        });
        fetchDailyCampaignIdeas();
        setloading(false);
      } catch (e) {
        setloading(false);
        console.log(e);
      }
    };
    initialize();
  }, []);

  const handleExploreClick = () => {
    setIsModalOpen(true); // Open the modal when "Explore Keywords" is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal when the user clicks "Close"
  };

  return (
    <div>
      {/** Title Part */}
      <div className="flex flex-row items-center my-[32px]">
        <h1
          className="w-[30%]"
          style={{ color: themeContext?.theme.titleColor }}
        >
          Campaigns
        </h1>
      </div>
      {/** Content */}
      <div className="flex flex-col md:flex-row gap-8">
        <div className="flex flex-col md:w-[60%] gap-y-[16px]">
          <div
            className="flex flex-col gap-x-[10px] rounded-[8px] p-6 gap-y-4"
            style={{ backgroundColor: themeContext?.theme.foreground }}
          >
            <div className="flex md:flex-row flex-col rounded-[8px] items-center gap-x-2 justify-between">
              <h3>Campaign Performance Summary &nbsp;&nbsp;

                <Tooltip title="Analyze performance metrics for your most recent campaign and get AI-driven recommendations to optimize bids, targeting, and creative strategies." enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
                  <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
                </Tooltip>
              </h3>
            </div>
            <select
              className="px-[12px] rounded-[50px] Button w-fit h-[2rem] mt-[1rem] md:mt-0"
              style={{
                backgroundColor: themeContext?.theme.buttonBackground,
                color: themeContext?.theme.color,
                border: `1px solid ${themeContext?.theme.activeButtonBackground}`,
              }}
              onChange={async (e) => {
                setCampaignIndex(Number(e.target.value));
                setPerform(campaignData[Number(e.target.value)]?.data[0]);
                await fetchInsights(campaignData[Number(e.target.value)]?.data[0]);
              }}
            >
              {
                campaignData.length > 0 ? (
                  campaignData.map((item: any, index: number) => (
                    <option value={index}>{item.campaign_name}</option>
                  ))
                ) : (<></>)
              }


            </select>
            {/* <h5>{perform.name}</h5> */}
            <PerformanceSummaryTable data={perform} />
            <InfoAlert str={insightData ? insightData : "Loading..."} />
          </div>
          <div
            className="flex flex-col gap-x-[10px] rounded-[8px] p-6 gap-y-4"
            style={{ backgroundColor: themeContext?.theme.foreground }}
          >
            <div className="flex flex-row rounded-[8px] items-center gap-x-2">
              <h3>Daily AI Campaign Ideas</h3>
              <Tooltip title="Explore AI-suggested campaign ideas based on keyword trends, audience behavior, and performance patterns to maximize ROI." enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
                  <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
                </Tooltip>
              <button
                className="flex items-center justify-center p-3 rounded-full border bg-gray-200 hover:bg-gray-300 transition ml-auto"
                onClick={() => fetchDailyCampaignIdeas()}
                aria-label="Refresh Ideas"
              >
                <RefreshIcon sx={{ width: 15, height: 15, cursor: 'pointer' }} />
                <span className="ml-2 text-xs text-gray-700">Refresh Ideas</span>
              </button>
            </div>
            <CampaignIdeasTable data={campaignIdeas} info={campaignIdeasStatus} />
          </div>
          <div
            className="flex flex-col gap-x-[10px] rounded-[8px] p-6 gap-y-4"
            style={{ backgroundColor: themeContext?.theme.foreground }}
          >
            <div className="flex flex-row rounded-[8px] items-center gap-x-2">
              <h3>Top Keyword and Strategy Opportunities</h3>
              <Tooltip title="Uncover high-performing keywords and actionable strategic opportunities to enhance campaign effectiveness and audience reach" enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
                <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
              </Tooltip>
            </div>
            <KeyTable keyword={keywords} limit={3} />
            <div className="flex justify-end title-f12-700">
              <button
                className="px-[15px] rounded-[50px] w-fit"
                style={{
                  backgroundColor: themeContext?.theme.activeButtonBackground,
                  color: themeContext?.theme.activeColor,
                }}
                onClick={() => handleExploreClick()}
              >
                Explore Opportunities
              </button>
            </div>
            <Modal
              open={isModalOpen}
              onClose={handleCloseModal}
              aria-labelledby="modal-keywords-title"
              aria-describedby="modal-keywords-description"

            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-start', // Align items to the start
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '70%',
                  bgcolor: 'background.paper',
                  borderRadius: '10px',
                  boxShadow: 24,
                  p: 4,
                  maxHeight: '80%', // Limit height
                  overflowY: 'auto',
                  minHeight: '400px', // Ensure minimum height for visibility
                }}
              >
                <Typography id="modal-keywords-title"
                  variant="h6"
                  component="h2"
                  sx={{ textAlign: 'center', marginBottom: 3 }}>
                  Explore All Keywords
                </Typography>
                <Button
                  onClick={handleCloseModal}
                  sx={{ position: 'absolute', top: '10px', right: '10px' }}
                >
                  Close
                </Button>
                <KeyTable key={perform.id} keyword={keywords} />
              </Box>
            </Modal>
          </div>
        </div>
        <div
          className="flex flex-col md:w-[40%] rounded-[8px] p-6 gap-y-4"
          style={{ backgroundColor: themeContext?.theme.foreground }}
        >
          <div className="flex flex-row items-center gap-x-[10px]">
            <h3>Competitor Campaign Insights</h3>
            <Tooltip title="Benchmark your campaign performance against competitors in terms of impression share, CTR, and conversions. Identify areas for improvement." enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
              <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
            </Tooltip>
          </div>
          <div
            className="flex flex-col p-4 gap-y-2 rounded-[8px]"
            style={{ border: `1px solid #666666` }}
          >
            <p className="Button">TOP COMPETITOR - INDUSTRY</p>
            <h5 className="title-f20-700">{topCompetitor ? topCompetitor.industry : ''}</h5>
            {/*<div className="flex items-center gap-x-2">
              <GoogleIcon />
              <p className="Button">Campaign: {topCompetitor ? topCompetitor.name : ''}</p>
            </div>*/}
            <div className="flex  flex-col gap-2">
              <CamaignItem
                icon={<RemoveRedEyeOutlinedIcon style={{ height: "12px" }} />}
                text="Impressions"
                performance={topCompetitor ? topCompetitor.impressions?.toLocaleString() : '0'}
              />
              <CamaignItem
                icon={<ShoppingCartOutlinedIcon sx={{ height: 12 }} />}
                text="Conversions"
                performance={topCompetitor ? topCompetitor.conversions : '0'}
              />
            </div>

            <div className="flex md:flex-row flex-col gap-2">
              <CamaignItem
                icon={<FavoriteLaptopIcon />}
                text="CTR"
                performance={`${topCompetitor ? (topCompetitor.ctr * 100).toFixed(2) : '0'}%`}
              />
              <CamaignItem
                icon={<SellOutlinedIcon sx={{ height: 12 }} />}
                text="CPA"
                performance={`$${topCompetitor ? topCompetitor.cpa : '0'}`}
              />
            </div>
            {/* <InfoAlert str="+15% lift in conversions" /> */}
          </div>
          <div
            className="flex flex-col p-4 gap-y-2 rounded-[8px]"
            style={{ border: `1px solid #666666` }}
          >
            <p className="Button">YOUR TOP CAMPAIGN</p>
            <div className="flex items-center gap-x-2">
              <GoogleIcon />
              <p className="Button">
                {topCampaign.name ? topCampaign.name : ''}
              </p>
            </div>
            <div className="flex  flex-col gap-2">
              <CamaignItem
                icon={<RemoveRedEyeOutlinedIcon style={{ height: "12px" }} />}
                text="Impressions"
                performance={topCampaign.impressions?.toLocaleString()}
              />
              <CamaignItem
                icon={<ShoppingCartOutlinedIcon sx={{ height: 12 }} />}
                text="Conversions"
                performance={topCampaign.conversions?.toLocaleString()}
              />
            </div>

            <div className="flex md:flex-row flex-col gap-2">
              <CamaignItem
                icon={<FavoriteLaptopIcon />}
                text="CTR"
                performance={`${topCampaign.ctr ? (topCampaign.ctr * 100).toFixed(2) : 0}%`}
              />
              <CamaignItem
                icon={<SellOutlinedIcon sx={{ height: 12 }} />}
                text="CPA"
                performance={`$${topCampaign.cpa ? topCampaign.cpa.toFixed(2) : 0}`}
              />
            </div>

            <InfoAlert str={topCompetitorInsight} />
          </div>
          {/* <div className="flex justify-end title-f12-700">
            <button
              className="px-[15px] rounded-[50px] w-fit"
              style={{
                backgroundColor: themeContext?.theme.activeButtonBackground,
                color: themeContext?.theme.activeColor,
              }}
            >
              View Campaign Insights
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};


export const CamaignItem: React.FC<{
  icon: ReactElement;
  text: string;
  performance: string;
}> = ({ icon, text, performance }) => {
  const themeContext = useContext(ThemeContext);
  return (
    <div
      className="flex flex-row justify-between items-center gap-x-[8px] py-[8px] px-[8px] rounded-[2px] w-full"
      style={{ backgroundColor: themeContext?.theme.activeColor }}
    >
      <div className="flex items-center gap-x-[2px]">
        {icon}
        <label className="b5">{text}</label>
      </div>
      <label className="Button" style={{ textAlign: "end" }}>
        {performance}
      </label>
    </div>
  );
};
