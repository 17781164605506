import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../components/Theme/context";
import { imageAssets, SERVER_URL } from "../../utils/constant";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import CalendarViewDayOutlinedIcon from "@mui/icons-material/CalendarViewDayOutlined";
import { ItemCreatives } from "../component/itemCretives";
import { CreativesTable } from "./creativesTable";
import { GoogleIcon } from "../component/icons";
import { Perform } from "../interface";
import axios from "axios";
import { formatNumber, getTopPerformingCampaignIndex } from "../../utils/helper";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UserContext from "../../utils/userContext";
export const Creatives = () => {
  const themeContext = useContext(ThemeContext);
  const { setloading } = useContext(UserContext);
  const [topTitle, setTopTitle] = useState('');
  const [format, setFormat] = useState();
  const [perform, setPerform] = useState<Perform>({
    impressions: 0,
    clicks: 0,
    ctr: 0,
    cpa: 0,
    conversions: 0,
  });
  const access_token = localStorage.getItem("access_token");
  const customerId = localStorage.getItem("customerId")?.replace(/-/g, '');
  const initialze = async () => {
    try {
      setloading(true);
      const result = await axios.post(`${SERVER_URL}/creatives`, {
        refresh_token: access_token,
        customerId
      });
      // fetch performance metrics datas
      const topCampaignIndex = getTopPerformingCampaignIndex(result.data?.perform, 'impressions');
      setPerform(result.data?.perform[topCampaignIndex]?.data[0]);
      setTopTitle(result.data?.perform[topCampaignIndex]?.campaign_name);

      const format = await axios.post(`${SERVER_URL}/creatives/format`, {
        refresh_token: access_token,
        customerId
      });
      setFormat(format.data);
      setloading(false);
    } catch (e) {
      console.log(e);
      setloading(false);
    }
  };
  useEffect(() => {
    initialze();
  }, []);
  return (
    <div>
      {/** Title Part */}
      <div className="flex flex-row items-center my-[32px]">
        <label
          className="title-f40-700 w-[30%]"
          style={{ color: themeContext?.theme.titleColor }}
        >
          Creatives
        </label>
      </div>
      {/** Content Part */}

      <div className="flex flex-col md:grid md:grid-cols-12 gap-[16px]">
        <div
          className="col-span-6 rounded-[8px] md:p-[24px] p-[16px] flex flex-col gap-[16px] relative"
          style={{ backgroundColor: themeContext?.theme.foreground }}
        >
          <div className="flex flex-row justify-between ">
            <label className="font-h3-700">
              Creative Performance Dashboard{" "}
              <Tooltip title="Track performance metrics like impressions, CTR, and conversions for each creative type (Search, Display, Video, Shopping)." enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
                  <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
                </Tooltip>
            </label>
          </div>
          <div className="flex flex-row gap-[4px] md:gap-[8px]">

          <select
                className="px-[12px] rounded-[50px] Button w-[6rem]"
                style={{
                  backgroundColor: themeContext?.theme.buttonBackground,
                  color: themeContext?.theme.color,
                  border: `1px solid ${themeContext?.theme.activeButtonBackground}`,
                }}
                onChange={(e) => {
                 
                }}
              >
                <option value="30days">30 days</option>
                <option value="currentYear">current year</option>
              </select>
         
            <button
              className="px-[12px] rounded-[50px] Button "
              style={{
                backgroundColor: themeContext?.theme.buttonBackground,
                color: themeContext?.theme.color,
                border: `1px solid ${themeContext?.theme.activeButtonBackground}`,
              }}
            >
              Text
            </button>
            <button
              className="px-[12px] rounded-[50px] Button "
              style={{
                backgroundColor: themeContext?.theme.buttonBackground,
                color: themeContext?.theme.color,
                border: `1px solid ${themeContext?.theme.activeButtonBackground}`,
              }}
            >
              Image
            </button>
            <button
              className="px-[12px] rounded-[50px] Button "
              style={{
                backgroundColor: themeContext?.theme.buttonBackground,
                color: themeContext?.theme.color,
                border: `1px solid ${themeContext?.theme.activeButtonBackground}`,
              }}
            >
              video
            </button>
            <button
              className="px-[12px] rounded-[50px] Button "
              style={{
                backgroundColor: themeContext?.theme.buttonBackground,
                color: themeContext?.theme.color,
                border: `1px solid ${themeContext?.theme.activeButtonBackground}`,
              }}
            >
              shopping
            </button>
          </div>

          <div
            className="flex flex-col gap-[16px] p-[16px] rounded-[4px]"
            style={{ backgroundColor: themeContext?.theme.inputBackground }}
          >
            <p className="font-buttom-700 font-faded">Top Performer</p>
            <div
              style={{ backgroundColor: themeContext?.theme.activeColor }}
              className="flex flex-row gap-[8px]"
            >
              <img src={imageAssets.performer} alt="creatives" />
              <div className="flex flex-row gap-[8px] p-[8px]">
                <GoogleIcon />
                <p className="font-button-700">
                  {topTitle}
                </p>
              </div>
            </div>
            <div className="flex flex-row gap-[8px]">
              <ItemCreatives
                icon={<VisibilityOutlinedIcon style={{ fontSize: "8px" }} />}
                title="Impressions"
                content={formatNumber(perform.impressions? perform.impressions : 0)}
              />
              <ItemCreatives
                icon={<VisibilityOutlinedIcon style={{ fontSize: "8px" }} />}
                title="Views"
                content="300,000"
              />
            </div>
            <ItemCreatives
              icon={<ShoppingCartOutlinedIcon style={{ fontSize: "8px" }} />}
              title="Conversions"
              content={`${formatNumber(perform.conversions ? perform.conversions : 0)} sign-ups`}
            />
            <div className="flex flex-row gap-[8px]">
              <ItemCreatives
                icon={<ComputerOutlinedIcon style={{ fontSize: "8px" }} />}
                title="CTR"
                content={perform.ctr? (perform.ctr * 100).toFixed(2).toString() : '0'}
              />
              <ItemCreatives
                icon={<DiscountOutlinedIcon style={{ fontSize: "8px" }} />}
                title="CPA"
                content={`$${perform.cpa?.toString()}`}
              />
            </div>
          </div>

          <div className=" border-[1px] border-[#4152EC] rounded-[2px] p-[23px] flex flex-row gap-[8px] self-center">
            <ArrowCircleUpIcon
              style={{ color: "#4152EC", fontSize: "16px" }}
              className="self-center"
            />
            <h5 className="font-h5-700">
              Videos are currently outperforming static creatives; consider
              increasing your video ad spend for Crypto campaigns.
            </h5>
          </div>
          <div className="mb-[24px]">
            <button
              className="px-[12px] rounded-[50px] font-button-700 w-fit h-[28px]"
              style={{
                backgroundColor: themeContext?.theme.activeButtonBackground,
                color: themeContext?.theme.activeColor,
                position: "absolute",
                right: "16px",
                bottom: "16px",
              }}
            >
              View Full Performance
            </button>
          </div>
        </div>
        <div className="col-span-6 rounded-[8px]  flex flex-col gap-[16px]">
          <div
            style={{ backgroundColor: themeContext?.theme.foreground }}
            className="flex flex-col gap-[16px] p-[24px]"
          >
            <div className="flex flex-row justify-between ">
              <label className="font-h3-700 ">
                AI-Powered Creative Suggestions{" "}
                <Tooltip title="Get actionable recommendations from AI to optimize your creatives, such as improving ad copy or testing new formats." enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
                  <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
                </Tooltip>
              </label>
            </div>

            <div
              className="flex flex-col gap-[16px] p-[16px] rounded-[4px]"
              style={{ backgroundColor: themeContext?.theme.inputBackground }}
            >
              <p className="font-buttom-700 font-faded">Top Performer</p>
              <div className="flex flex-row gap-[8px] w-full">
                <div
                  className="flex flex-col gap-[8px] p-[16px] w-1/2"
                  style={{ backgroundColor: themeContext?.theme.activeColor }}
                >
                  <GoogleIcon />
                  <p className="font-button-700">
                    {topTitle}
                  </p>
                </div>
                <div className="flex flex-col gap-[8px] w-1/2">
                  <ItemCreatives
                    icon={<ComputerOutlinedIcon style={{ fontSize: "8px" }} />}
                    title="CTR"
                    content={`${perform.ctr? (perform.ctr * 100).toFixed(2).toString() : '0'}%`}
                  />
                  <ItemCreatives
                    icon={
                      <ShoppingCartOutlinedIcon style={{ fontSize: "8px" }} />
                    }
                    title="Conversions"
                    content={formatNumber(perform.conversions ? perform.conversions : 0)}
                  />
                </div>
              </div>
              <div className=" border-[1px] border-[#292929] rounded-[4px] p-[8px] flex flex-row gap-[8px] self-center ">
                <ArrowCircleUpIcon
                  style={{ color: "#4152EC", fontSize: "16px" }}
                  className="self-center"
                />
                <h5 className="font-h5-700">
                  Add a call-to-action overlay at the 15-second mark to improve
                  conversion rates by an estimated 10%.
                </h5>
                <button
                  className="px-[12px] rounded-[50px] font-button-700 w-fit h-[28px]"
                  style={{
                    backgroundColor: themeContext?.theme.activeButtonBackground,
                    color: themeContext?.theme.activeColor,
                  }}
                >
                  Apply
                </button>
              </div>
            </div>

            <div
              className="flex flex-col gap-[16px] p-[16px] rounded-[4px]"
              style={{ backgroundColor: themeContext?.theme.inputBackground }}
            >
              <p className="font-buttom-700 font-faded">
                New Creative Concepts
              </p>
              <div className="flex flex-col md:flex-row gap-[8px] w-full">
                <div
                  className="flex flex-row gap-[8px] p-[16px] md:w-1/2 items-center"
                  style={{ backgroundColor: themeContext?.theme.activeColor }}
                >
                  <GoogleIcon />
                  <p className="font-button-700">
                    Why REITs Are the Safe Haven in Market Volatility
                  </p>
                </div>
                <div className="flex flex-col gap-[8px] md:w-1/2">
                  <ItemCreatives
                    icon={
                      <FavoriteBorderOutlinedIcon style={{ fontSize: "8px" }} />
                    }
                    title="Predicted Engagement Rate"
                    content="7.2%"
                  />
                  <ItemCreatives
                    icon={
                      <CalendarViewDayOutlinedIcon
                        style={{ fontSize: "8px" }}
                      />
                    }
                    title="Suggested Channel"
                    content="Facebook Ads"
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row gap-[8px] w-full">
                <div
                  className="flex flex-row gap-[8px] p-[16px] md:w-1/2 items-center"
                  style={{ backgroundColor: themeContext?.theme.activeColor }}
                >
                  <GoogleIcon />
                  <p className="font-button-700 self-center">
                    Crypto vs. Stocks: Where to Invest Now
                  </p>
                </div>
                <div className="flex flex-col gap-[8px] md:w-1/2">
                  <ItemCreatives
                    icon={
                      <ShoppingCartOutlinedIcon style={{ fontSize: "8px" }} />
                    }
                    title="Predicted Conversions"
                    content="950 sign-ups"
                  />
                  <ItemCreatives
                    icon={
                      <CalendarViewDayOutlinedIcon
                        style={{ fontSize: "8px" }}
                      />
                    }
                    title="Suggested Channel"
                    content="Instagram Stories"
                  />
                </div>
              </div>

              <div className=" border-[1px] border-[#292929] rounded-[4px] p-[8px] flex flex-row gap-[8px] self-center ">
                <ArrowCircleUpIcon
                  style={{ color: "#4152EC", fontSize: "16px" }}
                  className="self-center"
                />
                <h5 className="font-h5-700">
                  Add a call-to-action overlay at the 15-second mark to improve
                  conversion rates by an estimated 10%.
                </h5>
              </div>
            </div>

            <div style={{ position: "relative" }} className="mb-[24px]">
              <button
                className="px-[12px] rounded-[50px] font-button-700 w-fit h-[28px]"
                style={{
                  backgroundColor: themeContext?.theme.activeButtonBackground,
                  color: themeContext?.theme.activeColor,
                  position: "absolute",
                  right: "0px",
                }}
              >
                Generate Suggestions
              </button>
            </div>
          </div>

          <div
            style={{ backgroundColor: themeContext?.theme.foreground }}
            className="flex flex-col gap-[16px] p-[24px]"
          >
            <div className="flex flex-row justify-between ">
              <label className="font-h3-700 ">
                Top Creative Formats{" "}
                <Tooltip title="Analyze the engagement and conversion rates of different creative formats (Search, Display, Video) to identify what works best." enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
                  <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
                </Tooltip>
              </label>
            </div>
            <CreativesTable data= {format} />
          </div>
        </div>
      </div>
    </div>
  );
};
