import React, { useContext, useRef } from "react";
import { useSpring, animated } from '@react-spring/web';
import { ThemeContext } from "../../components/Theme/context";

export interface Notification {
    id: number;
    message: string;
    dateTime: Date;
}

interface NotificationBubbleProps {
    notifications: Notification[];
    closeBubble: () => void;
    onNotificationClick: (id: number) => void; 
}

const NotificationBubble: React.FC<NotificationBubbleProps> = ({
    notifications,
    closeBubble,
    onNotificationClick,
}) => {
    const themeContext = useContext(ThemeContext);
    const bubbleRef = useRef<HTMLDivElement | null>(null);
    const listRef = useRef<HTMLUListElement | null>(null);

    const animationProps = useSpring({
        opacity: 1,
        transform: "translateY(0)",
        from: { opacity: 0, transform: "translateY(-20px)" },
    });

    const formatDateTime = (date: Date) => {
        const options: Intl.DateTimeFormatOptions = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        };
        return date.toLocaleDateString('en-US', options);
    };

    return (
        <animated.div
            className="absolute top-[80px] right-0 mr-4 w-[300px] max-h-[80vh] p-[10px] bg-[#6a4c9c] rounded-[5px] shadow-lg z-10 overflow-y-auto"
            style={animationProps}
            ref={bubbleRef}
        >
            <div className="flex justify-between items-center mb-[10px]">
                <label
                    className="text-white font-semibold"
                    style={{ color: themeContext?.theme.titleColor }}
                >
                    Notifications
                </label>
                <button
                    className="bg-none border-none text-white text-lg cursor-pointer"
                    onClick={closeBubble}
                >
                    x
                </button>
            </div>
            <ul ref={listRef} className="list-none p-0 m-0">
                {notifications.map((notification) => (
                    <li
                        key={notification.id}
                        className="p-[8px] bg-[#2c1e62] text-white rounded-[5px] mb-[10px] shadow-sm border-b-2 border-[#9e7cc5] cursor-pointer"
                        onClick={() => onNotificationClick(notification.id)}
                    >
                        <div className="font-medium break-words">{notification.message}</div>
                        <div className="text-xs text-gray-300 mt-[5px]">
                            {formatDateTime(notification.dateTime)}
                        </div>
                    </li>
                ))}
            </ul>
        </animated.div>
    );
};

export default NotificationBubble;
