import { useContext } from "react";
import { ThemeContext } from "../../components/Theme/context";
import { GoogleIcon } from "../component/icons";

export const PerformanceSummaryTable = (data : any) => {
  const themeContext = useContext(ThemeContext);
  return (
    <table className="text-left b5 w-full md:overflow-auto overflow-scroll block  md:table ">
      <thead>
        <tr>
          <th>Channel</th>
          <th>Impressions</th>
          <th>Clicks</th>
          <th>CTR</th>
          <th>CPA</th>
          {/* <th>AI Score</th> */}
          {/* <th>Action</th> */}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <GoogleIcon />
          </td>
          <td>{data.data.impressions}</td>
          <td>{data.data.clicks}</td>
          <td>{(data.data.ctr * 100).toFixed(2)}%</td>
          <td>${data.data.cpa}</td>
          {/* <td>30/100</td> */}
          {/* <td>
            <div className="flex items-center">
              <button
                className="px-[15px] Button rounded-[50px]"
                style={{
                  backgroundColor: themeContext?.theme.activeButtonBackground,
                  color: themeContext?.theme.activeColor,
                }}
              >
                Optimize
              </button>
            </div>
          </td> */}
        </tr>
      </tbody>
    </table>
  );
};
