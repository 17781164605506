
import {  GoogleIcon } from '../component/icons';
export const CreativesTable = ({ data }: { data: any}) => {
    const formatChannelType = (channelType: number) => {
        switch(channelType) {
            case 2:
              return 'Search Ads';
              break;
          case 3:
              return 'Display Ads';
              break;
          case 4:
              return 'Shopping Ads';
              break;
          case 5:
              return 'Hotel Ads';
              break;
          case 6:
              return 'Video Ads';
              break;
          case 7:
              return 'Multi Channel';
              break;
          case 8:
              return 'Local Ads';
              break;
          case 9:
              return 'Smart Ads';
              break;
          case 10:
              return 'Discovery Ads';
              break;
          default:
              return 'Unknown';
        }
    };
    return (
        <table className="text-left b5 w-full md:overflow-auto overflow-scroll block md:table">
            <thead>
                <tr>
                    <th>Format</th>
                    <th>Channels</th>
                    <th>Average Engagement Rate</th>
                    <th>Average Conversion Rate</th>
                    <th>CPA</th>
                </tr>
            </thead>
            <tbody>

                {data?.map((item : any, index : any) => (
                    <tr key={index}>
                        <td>{formatChannelType(item.channelType)}</td>
                        <td><GoogleIcon /></td>
                        <td>{(item.campaignData.average_engagement_rate * 100).toFixed(2)}%</td>
                        <td>{(item.campaignData.average_conversion_rate * 100).toFixed(2)}%</td>
                        <td>${(item.campaignData.average_cpc / 1000000).toFixed(2)}</td>
                    </tr>
                ))}

            </tbody>
        </table>
    );
};
