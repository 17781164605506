import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../components/Theme/context";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import BedtimeOutlinedIcon from "@mui/icons-material/BedtimeOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import { iconAssets, imageAssets, SERVER_URL } from "../../utils/constant";
import Badge from "@mui/material/Badge";
import UserContext from "../../utils/userContext";
import { useRouter } from "../../routes/hooks";
import { Notification } from "../component/notificationBubble";
import NotificationBubble from "../component/notificationBubble";
import { collection, getDocs, query, where, setDoc, addDoc } from "firebase/firestore";
import { firestore } from '../../components/Firebase/firebase';

import { getUserDataByMail } from "../../utils/helper";
import axios from "axios";
import toast from "react-hot-toast";
import { Button, Modal } from "antd";

export const Navbar = () => {
  const themeContext = useContext(ThemeContext);
  const [theme, setTheme] = useState(themeContext?.theme.name);
  const { siderbar, setSiderbar, firstName, avatar, setloading } = useContext(UserContext);
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const router = useRouter();
  const [industry, setIndustry] = useState<string>("");
  const [customerIds, setCustomerIds] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>('');
  const [showDot, setShowDot] = useState(false);

  const email = localStorage.getItem('email');

  const notifications: Notification[] = [
    {
      id: 1,
      message: `Your account was created successfully with the email ${email} You can continue the onboarding flow, or edit you current data in the settings.`,
      dateTime: new Date(),
    },
  ];

  const toggleNotifications = () => {
    if (showNotifications) {
      setShowDot(false);
      localStorage.setItem('hasSeenNotification', 'true');
    }
    setShowNotifications(!showNotifications);
  };

  useEffect(() => {
    const hasSeenNotification = localStorage.getItem('hasSeenNotification');
    if (hasSeenNotification) {
      setShowDot(false);
    } else {
      setShowDot(true);
    }
  }, [showDot]);

  const handleThemeChange = () => {
    themeContext?.toggleTheme();
    setTheme((prev) => (prev === "light" ? "dark" : "light"));
  };
  const customerId = localStorage.getItem("customerId");
  const handleGoToSettings = () => {
    router.push(`settings`);
  };
  const userName = localStorage.getItem('userName');

  useEffect(() => {
    const fetchData = async () => {
      const mail = localStorage.getItem('email');
      if (mail) {
        const data = await getUserDataByMail(mail);
        if (data) {
          setIndustry(data.experience.industry);
        }
      }
    }
    fetchData();
  }, []);


  const connectAnalytics = async (token: any) => {
    setloading(true);
    try {

      const response = await axios.post(`${SERVER_URL}/customers`, {
        refresh_token: token,
      });
      setCustomerIds(response.data);
      setShowModal(true);


      setloading(false);

    } catch (e) {
      toast.error('Error fetching analytics data:' + e);
      setloading(false);
    }
    setloading(false);
  }

  const handleSelectCustomer = async (customerId: string) => {
    setloading(true);
    setSelectedCustomerId(customerId);
    localStorage.setItem('customerId', customerId);
    await saveData(customerId);
    setShowModal(false);
    router.reload();
    setloading(false);
    // Close modal here if needed
  };

  const saveData = async (customerId: string) => {
    const mail = localStorage.getItem('email');
    if (!mail) {
      toast.error('No email found in local storage.');
      return; // Exit if no email is found
    }

    try {
      const userQuery = query(collection(firestore, "users"), where("mail", "==", mail));
      const querySnapshot = await getDocs(userQuery);
      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (doc) => {
          await setDoc(doc.ref, { customerId }, { merge: true });
          console.log("Document updated with ID: ", doc.id);
        });
      } else {
        console.log("No documents found for the provided email.");
      }
    } catch (e) {
      console.error('Error updating user data:', e);
      toast.error('Error updating user data.');
    }
  }

  const formatCustomerId = (customerId: string): string => {
    const id = customerId.split('/')[1]; // Remove the 'customers/' prefix
    return `${id.slice(0, 3)}-${id.slice(3, 6)}-${id.slice(6)}`; // Insert dashes
  };

  return (
    <div
      className="flex justify-between flex-row top-0 z-[50] sticky md:relative py-4 "
      style={{ backgroundColor: themeContext?.theme.background }}
    >
      <div className="flex flex-row item-center gap-x-[16px]">
        <div className="block md:hidden">
          <button className="Button " onClick={() => setSiderbar(true)}>
            <MenuIcon
              style={{
                color: themeContext?.theme.titleColor,
                fontSize: "40px",
              }}
            />
          </button>
        </div>
        {/* <div
          className="hidden md:flex justify-start items-center rounded-[8px] h-[48px] w-[300px] px-[16px] py-[12px] gap-[10px]"
          style={{
            backgroundColor: themeContext?.theme.inputBackground,
            boxShadow: `0px 1px 0px ${themeContext?.theme.shadowColor}`,
          }}
        >
          <SearchOutlinedIcon />
          <input
            type="text"
            className="bg-transparent outline-none w-full text-gray-700 placeholder-gray-500 letter-f12-700"
            placeholder="Search anything"
            value={query}
            onChange={handleInputChange}
          />
        </div> */}
        <div
          className="hidden md:flex justify-start items-center rounded-[8px] h-[48px] w-[178px] px-[16px] py-[12px] gap-[10px]"
          style={{
            backgroundColor: themeContext?.theme.inputBackground,
            boxShadow: `0px 1px 0px ${themeContext?.theme.shadowColor}`,
          }}
        >
          <BusinessIcon style={{ transform: "rotate(-90deg)" }} />
          <label className="letter-f12-700">{industry}</label>
        </div>
        <button
          className="hidden md:flex justify-start items-center rounded-[8px] h-[48px] w-[178px] px-[16px] py-[12px] gap-[10px]"
          style={{
            backgroundColor: themeContext?.theme.inputBackground,
            boxShadow: `0px 1px 0px ${themeContext?.theme.shadowColor}`,
          }}
          onClick={() => {
            const _token = localStorage.getItem('access_token');
            if (_token) {
              connectAnalytics(_token);
            }
          }}
        >
          <img src={iconAssets.ic_analyze} alt="Analyze" />
          <label className="letter-f12-700">{customerId}</label>
        </button>
      </div>

      <div className="flex flex-row justify-center items-center gap-x-[16px]">
        <img
          className="cursor-pointer"
          src={avatar == "" ? imageAssets.profile : avatar}
          alt="Profile"
          onClick={handleGoToSettings}
        />
        <div className="flex flex-col">
          <label>Welcome</label>
          <label>{userName}</label>
          {/* <label>{names}</label> */}
        </div>
        <div
          className="rounded-[100%] p-[10px] cursor-pointer"
          style={{ backgroundColor: themeContext?.theme.inputBackground }}
        >
          <Badge color="secondary" variant="dot" invisible={!showDot}>
            <NotificationsOutlinedIcon
              onClick={() => {
                if (showDot) toggleNotifications();
              }} />
          </Badge>
          {showNotifications && (
            <NotificationBubble
              notifications={notifications}
              closeBubble={toggleNotifications}
              onNotificationClick={(id: number) => { }}
            />
          )}
        </div>
        <div
          className="rounded-[100%] p-[10px] cursor-pointer"
          style={{ backgroundColor: themeContext?.theme.inputBackground }}
          onClick={handleThemeChange}
        >
          {theme === "light" ? (
            <BedtimeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </div>
      </div>
      <Modal
        title="Select a Google Ads Account"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null} // Remove default footer buttons
      >
        {customerIds.map((id) => (
          <Button key={id} type="primary" onClick={() => handleSelectCustomer(id)} style={{ margin: '8px' }}>
            {id}
          </Button>
        ))}
      </Modal>
    </div>
  );
};

