import React, { useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

export const CampaignImage: React.FC<{
    imageUrl: string | undefined;
    canDisplay: boolean;
}> = ({ imageUrl, canDisplay }) => {
    const [zoom, setZoom] = useState(1.2);
    const [isOriginalSize, setIsOriginalSize] = useState(false);

    const handleOriginalSizeToggle = () => {
        setIsOriginalSize((prev) => !prev);
        setZoom(isOriginalSize ? 1 : 1.2);
    };

    const handleMouseEnter = () => {
        if (!isOriginalSize) setZoom(1.2);
    };

    const handleMouseLeave = () => {
        if (!isOriginalSize) setZoom(1);
    };

    if (!canDisplay || !imageUrl) {
        return (
            <h5 className="text-center mt-16 mb-16 md:mb-0">
                No Creative Media
            </h5>
        );
    }

    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                maxWidth: '500px',
                height: '300px',
                overflow: 'hidden',
                borderRadius: '8px',
                cursor: 'pointer',
                transition: 'transform 0.3s ease',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {isOriginalSize && (
                <Typography
                    variant="caption"
                    style={{
                        position: 'absolute',
                        top: '10px',
                        left: '10px',
                        background: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        padding: '2px 8px',
                        borderRadius: '4px',
                        zIndex: 10,
                    }}
                >
                    Original Size
                </Typography>
            )}
            <IconButton
                style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    zIndex: 10,
                    background: 'rgba(0, 0, 0, 0.5)',
                    color: '#fff',
                }}
                onClick={handleOriginalSizeToggle}
                title="Toggle between original size and zoom"
            >
                <ZoomOutMapIcon />
            </IconButton>

            <img
                src={imageUrl}
                alt="Creative Media"
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: isOriginalSize ? 'contain' : 'cover',
                    objectPosition: 'center',
                    transition: 'transform 0.3s ease',
                    transform: `scale(${zoom})`,
                }}
            />
        </div>
    );
};
