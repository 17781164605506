import { useContext, useState, useEffect } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { Checkbox } from '@mui/material';
import CustomSelect, { CustomMenuItem } from '../component/customSelect';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UserContext from '../../utils/userContext';
import { UserData } from "../interface";
import {
  getUserDataByToken,
  updateUserDataByEmail
} from "../../utils/helper";
import {
  goalOptions,
  metricOptions,
  budgetOptions,
  preferredChannelOptions
} from "../../utils/constant";

const GoalsPage = () => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [budget, setBudget] = useState(['$1,000 - $5,000']);
  const [goal, setGoal] = useState<string[]>(['Increase Conversions']);
  const [metrics, setMetrics] = useState<string[]>(['CTR']);
  const [preferredChannels, setPreferredChannels] = useState<string[]>(['Google Ads']);
  const { setloading } = useContext(UserContext);

  const fetchUserData = async () => {
    const token = localStorage.getItem("access_token") ?? "";
    setloading(true);
    const data = await getUserDataByToken(token);
    if (data) {
      setUserData(data);
      setBudget(data.goals.budget);
      setGoal(data.goals.goal);
      setMetrics(data.goals.metrics);
      setPreferredChannels(data.goals.preferred);
    }
    setloading(false);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleUpdate = async (
    field: keyof UserData["goals"],
    value: any
  ) => {
    if (!userData) return;

    const updatedData = {
      ...userData,
      goals: { ...userData.goals, [field]: value },
    };
    setUserData(updatedData);
    setBudget(updatedData.goals.budget);
    setGoal(updatedData.goals.goal);
    setMetrics(updatedData.goals.metrics);
    setPreferredChannels(updatedData.goals.preferred);
    setloading(true);
    const response = await updateUserDataByEmail(userData.doc_id, {
      goals: { ...userData.goals, [field]: value },
    });

    if (!response.success) {
      console.error("Failed to update user data");
      setUserData(userData);
    }
    setloading(false);
  };

  const renderSelect = (
    field: keyof UserData["goals"],
    label: string,
    options: string[],
    value: string[],
    multiple = true
  ) => (
    <div className="flex flex-col gap-2">
      <div className="text-left font-medium text-gray-300">{label}</div>
      <CustomSelect
        IconComponent={(props) => <KeyboardArrowDownIcon {...props} sx={{ color: '#D9DCFB', fontSize: '30px' }} />}
        MenuProps={{ PaperProps: { sx: { backgroundColor: '#292929', borderRadius: '8px' } } }}
        multiple={multiple}
        renderValue={(selected: any) => typeof selected === 'string' ? selected : selected.join(', ')}
        value={value}
        onChange={(e: SelectChangeEvent<unknown>) => {
          var newValue = null;
          if (field === "budget") { // This is needed as the comma conflicts with the budget data.
            newValue = e.target.value;
          } else {
            newValue = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value;
          }
          if (Array.isArray(newValue) && newValue.length > 0) {
            handleUpdate(field, newValue);
          } else if (typeof newValue === 'string' && newValue.trim() !== '') {
            handleUpdate(field, newValue);
          }
        }}
      >
        {options.map((option) => (
          <CustomMenuItem key={option} value={option}>
            {multiple && <Checkbox checked={value.includes(option)} />}
            {option}
          </CustomMenuItem>
        ))}
      </CustomSelect>
    </div>
  );

  return (
    <div className="flex flex-col gap-6 md:gap-8 md:flex-row">
      <div className="flex flex-col w-full gap-6">
        { renderSelect('goal', 'Primary Goals', goalOptions, goal) }
        { renderSelect('metrics', 'Target Metrics', metricOptions, metrics) }
        { renderSelect('preferred', 'Preferred Channels', preferredChannelOptions, preferredChannels) }
        { renderSelect('budget', 'Budget Range', budgetOptions, budget, false) }
      </div>
    </div>
  );
};

export default GoalsPage;
