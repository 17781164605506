import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../components/Theme/context";
import EditIcon from '@mui/icons-material/Edit';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import { getUserDataByMail } from "../../utils/helper";
import { collection, getDocs, query, where, setDoc, addDoc } from "firebase/firestore";
import { firestore } from '../../components/Firebase/firebase';

import axios from "axios";
import toast from "react-hot-toast";
import { Button, Modal } from "antd";
import UserContext from "../../utils/userContext";
import { SERVER_URL } from "../../utils/constant";
import { useRouter } from "../../routes/hooks";
const Integration = () => {
    const { setloading } = useContext(UserContext);
    const [customerIds, setCustomerIds] = useState<string[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedCustomerId, setSelectedCustomerId] = useState<string>('');
    const themeContext = useContext(ThemeContext);
    const router = useRouter();

  const userName = localStorage.getItem('userName');
  const customerId = localStorage.getItem("customerId");

  const connectAnalytics = async (token: any) => {
    setloading(true);
    try {

      const response = await axios.post(`${SERVER_URL}/customers`, {
        refresh_token: token,
      });
      console.log(response.data)
      setCustomerIds(response.data);
      setShowModal(true);


      setloading(false);

    } catch (e) {
      toast.error('Error fetching analytics data:' + e);
      setloading(false);
    }
    setloading(false);
  }

  const handleSelectCustomer = async (customerId: string) => {
    setloading(true);
    setSelectedCustomerId(customerId);
    localStorage.setItem('customerId', customerId);
    await saveData(customerId);
    setShowModal(false);
    router.reload();
    setloading(false);
    // Close modal here if needed
  };

  const saveData = async (customerId: string) => {
    const mail = localStorage.getItem('email');
    if (!mail) {
      toast.error('No email found in local storage.');
      return; // Exit if no email is found
    }

    try {
      const userQuery = query(collection(firestore, "users"), where("mail", "==", mail));
      const querySnapshot = await getDocs(userQuery);
      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (doc) => {
          await setDoc(doc.ref, { customerId }, { merge: true });
          console.log("Document updated with ID: ", doc.id);
        });
      } else {
        console.log("No documents found for the provided email.");
      }
    } catch (e) {
      console.error('Error updating user data:', e);
      toast.error('Error updating user data.');
    }
  }

    return (
        <div className="flex flex-col">
            <div className='flex flex-col gap-[8px]'>
                <div className='font-h5-700 font-grey1 text-left'>
                    Analytics Account Name
                </div>
                <div className="flex flex-row gap-[32px]">
                    <input className='input-text font-b4-500 font-grey1 w-[60%]' type='text' placeholder='Analytics account' value={customerId? customerId : ''} />
                    <button
                        className="px-[12px] rounded-[50px] Button w-fit"
                        style={{
                            backgroundColor: themeContext?.theme.activeButtonBackground,
                            color: themeContext?.theme.activeColor,
                        }}
                        onClick={() => {
                            const _token = localStorage.getItem('access_token');
                            if (_token) {
                              connectAnalytics(_token);
                            }
                          }}
                        
                    >
                        <EditIcon style={{ fontSize: "13px" }} /> change
                    </button>
                    {/* <button
                        className="px-[12px] rounded-[50px] Button w-[121px] "
                        style={{
                            color: themeContext?.theme.activeButtonBackground,
                            background: 'none',
                            border:'1px solid #fff'
                        }}
                    >
                        <SensorsOffIcon style={{ fontSize: "13px" }} /> disconnect
                    </button> */}
                </div>
            </div>
            <Modal
        title="Select a Google Ads Account"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null} // Remove default footer buttons
      >
        {customerIds.map((id) => (
          <Button key={id} type="primary" onClick={() => handleSelectCustomer(id)} style={{ margin: '8px' }}>
            {id}
          </Button>
        ))}
      </Modal>
        </div>
    );
}
export default Integration;