import { useContext, useState } from "react";
import { ThemeContext } from "../../components/Theme/context";
import { IconButton } from "../layout/sidebar";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PreferencesIcon from '@mui/icons-material/SelectAll';

import LinkIcon from '@mui/icons-material/Link';
import InformationPage from "./information";
import Integration from "./integration";
import GoalsPage from "./goals";

const Settings = () => {
    const themeContext = useContext(ThemeContext);
    const [settingPage, setSettingPage] = useState(0);

    return (
        <div>
            {/** Title Part */}
            <div className="flex flex-row items-center my-[32px]">
                <label
                    className="title-f40-700 w-[30%]"
                    style={{ color: themeContext?.theme.titleColor }}
                >
                    Settings
                </label>
            </div>
            {/** Content Part */}
            <div className="flex md:flex-row flex-col gap-[32px] mt-[32px] w-full">
                <div className="md:w-[300px] w-full shrink-0 rounded-[8px] p-[32px] flex flex-col gap-[16px] md:h-[calc(100vh - 242px)] h-fit" style={{ backgroundColor: themeContext?.theme.foreground, }}>
                    <IconButton
                        key={0}
                        index={0}
                        isActive={settingPage === 0}
                        icon={<PersonOutlineIcon />}
                        text="Profile Information"
                        onClick={() => setSettingPage(0)}
                        isSidebar={true}
                    />
                    <IconButton
                        key={1}
                        index={1}
                        isActive={settingPage === 1}
                        icon={<PreferencesIcon />}
                        text="Preferences"
                        onClick={() => setSettingPage(1)}
                        isSidebar={true}
                    />
                    <IconButton
                        key={2}
                        index={2}
                        isActive={settingPage === 2}
                        icon={<LinkIcon />}
                        text="Integrations"
                        onClick={() => setSettingPage(2)}
                        isSidebar={true}
                    />
                </div>
                <div className="w-full mr-[24px] rounded-[8px] p-[32px] h-fit" style={{ backgroundColor: themeContext?.theme.foreground, }}>
                    <div className="flex flex-col gap-[16px] w-[90%] justify-self-center">
                        {(() => {
                            if (settingPage === 0) return <InformationPage />;
                            if (settingPage === 1) return <GoalsPage />;
                            if (settingPage === 2) return <Integration />;
                            return null;
                        })()}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Settings;