
export const PerformingTable = ({ perform ,limit}: { perform: any , limit : any}) => {

    return (
        <table className="text-left font-b4-500">
            <thead>
                <tr>
                    <th>Strategy</th>
                    <th>Channels</th>
                    <th>CTR</th>
                    <th>Conversion Rate</th>
                    <th>CAC</th>
                </tr>
            </thead>
            <tbody>
                {
                    perform ? perform.slice(0, limit).map((item:any) => {
                        return (
                            <tr>
                            <td>{item.strategyName}</td>
                            <td>{item.channel}</td>
                            <td>{item.ctr}%</td>
                            <td>{item.conversionRate}%</td>
                            <td>${item.cac}</td>
                        </tr>
                        )
                    }) : 
                    (
                        <tr>
                        <td></td>
                        <td></td>
                        <td>0%</td>
                        <td>0%</td>
                        <td>$0</td>
                    </tr>
                    )
                }
               

                
            </tbody>
        </table>
    );
};