import {
    useCallback,
    useContext, useEffect, useState
} from "react";
import { ThemeContext } from "../../components/Theme/context";

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { InfoAlert } from "../component/infoAlert";
import { ItemList, ItemListNegative } from "../component/itemList";
import { ItemInfoList } from "../component/itemInfoList";
import { PerformingTable } from "./performingTable";
import { KeyTable } from "./keyTable";
import { BarChartComponent, ChartData } from "./BarChart";
import { Divider, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import perform from '../../services/perform.json';

import { Modal, Box, Typography, Button } from '@mui/material';
import axios from "axios";
import { SERVER_URL } from "../../utils/constant";
import { calculateTotals, getUserDataByMail, sumAudienceGender, sumCampaigns, sumLocation } from "../../utils/helper";
import UserContext from "../../utils/userContext";

export const Analaytics = () => {
    const themeContext = useContext(ThemeContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { setloading } = useContext(UserContext);
    // const [keywords, setKeywords] = useState([]);
    const [performs, setPerforms] = useState<any>({});
    const [perform, setPerform] = useState<any>([]);
    const [maxGrown, setMaxGrown] = useState<any>({});
    const [minGrown, setMinGrown] = useState<any>({});
    const [audienceGender, setAudienceGender] = useState<any> ({});
    const [topGender, setTopGender] = useState ('') ;
    const [insightData, setInsightData] = useState<any>("");
    const [insightRising, setInsightRising] = useState<any>("");
    const [audienceResult, setAudienceResult] = useState<TopImpressionsResult>({
        top1: { ageRange: '', impression: 0, percent: "0" },
        top2: { ageRange: '', impression: 0, percent: "0" },
        totalImpressions: 0,
    });
    const [dateValue, setDateValue] = useState<any>([]);
    const [maxPos, setMaxPos] = useState(0);
    const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const ageRangeMapping: { [key: string]: string } = {
        "503001": "18-24",
        "503002": "25-34",
        "503003": "35-44",
        "503004": "45-54",
        "503005": "55-64",
        "503006": "65 or more",
        "503999": "Unknown",
    };
    const dateData: ChartData = {
        data: [4000, 3000, 2000, 2780, 1890, 2390, 3490],
        label: ["Mon", "Tue", "Wed", "Thrs", "Fri", "Sat", "Sun"],
    }

    interface Perform {
        impressions: number;
        clicks: number;
        ctr: number;
        cpa: number;
        conversions: number;
    }
    interface ImpressionData {
        age_range: number;
        impression: number;
    }


    interface TopImpressionsResult {
        top1: { ageRange: string; impression: number; percent: string };
        top2: { ageRange: string; impression: number; percent: string };
        totalImpressions: number;
    }

    const handleExploreClick = () => {
        setIsModalOpen(true); // Open the modal when "Explore Keywords" is clicked
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Close the modal when the user clicks "Close"
    };

    const fetchInsights = useCallback(async (campaign: any) => {
        console.warn("Fetch Insights...");
        setInsightData("Loading...");
        setloading(true);
        try {
          const mail = localStorage.getItem('email') ?? "";
          const userData = await getUserDataByMail(mail);
          if (userData) {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/campaigns/insights`, {
              user: userData,
              campaign,
            });

            setInsightData(response.data || "Not found");
            setloading(false);
          } else {
            setInsightData("Not found");
            setloading(false);
          }
        } catch (error) {
          console.warn("Error fetching insights:", error);
          setInsightData("Unexpected Error");
          setloading(false);
        }
      }, []);

    const fetchInsightsRising = useCallback(async (maxCampaign: any, minCampaign : any ,growthRates : any) => {
        console.warn("Fetch RisingInsights...");
        setInsightRising("Loading...");
        setloading(true);
        try {
          const mail = localStorage.getItem('email') ?? "";
          const userData = await getUserDataByMail(mail);
          if (userData) {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/analytics/insightsRising`, {
              user: userData,
              maxCampaign,
              minCampaign,
              growthRates
            });
            setInsightRising(response.data || "Not found");
            setloading(false);
          } else {
            setInsightRising("Not found");
            setloading(false);
          }
        } catch (error) {
          console.warn("Error fetching insights:", error);
          setInsightRising("Unexpected Error");
          setloading(false);
        }
      }, []);
    const access_token = localStorage.getItem('access_token');
    const customerId = localStorage.getItem("customerId")?.replace(/-/g, '');
    useEffect(() => {
        initialze();
    }, []);

    const initialze = async () => {
        try {
            setloading(true);
            const result = await axios.post(`${SERVER_URL}/analytics`, {
                refresh_token: access_token,
                customerId,
                days: 7
            });
            // fetch performance metrics datas
            // setKeywords(result.data.keywords);
            setPerform(result.data?.perform);
            setPerforms(calculateTotals(result.data?.perform));
            await fetchInsights(calculateTotals(result.data?.perform));
            const oldData = sumCampaigns(result.data?.lastMonthMetrics);
            const newData = sumCampaigns(result.data?.currentMonthMetrics);
            let growthRates = oldData.map((oldItem: any, index: any) => {
                let newItem = newData[index];  // Assuming the campaigns are in the same order in both arrays

                // Calculate the growth rate for each metric
                let growthRateData = {
                    id: oldItem.data.id,  // Assuming the id is the same for both old and new data
                    name: oldItem.data.name,  // Assuming the name is the same for both old and new data
                    impressions: oldItem.data.impressions !== 0 ? ((newItem.data.impressions - oldItem.data.impressions) / oldItem.data.impressions) * 100 : 0,
                    clicks: oldItem.data.clicks !== 0 ? ((newItem.data.clicks - oldItem.data.clicks) / oldItem.data.clicks) * 100 : 0,
                    conversions: oldItem.data.conversions !== 0 ? ((newItem.data.conversions - oldItem.data.conversions) / oldItem.data.conversions) * 100 : 0,
                    ctr: oldItem.data.ctr !== 0 ? ((newItem.data.ctr - oldItem.data.ctr) / oldItem.data.ctr) * 100 : 0,
                    cpa: oldItem.data.cpa !== 0 ? ((newItem.data.cpa - oldItem.data.cpa) / oldItem.data.cpa) * 100 : 0,
                    cost: oldItem.data.cost !== 0 ? ((newItem.data.cost - oldItem.data.cost) / oldItem.data.cost) * 100 : 0
                };

                return {
                    campaign_name: oldItem.campaign_name,
                    data: growthRateData
                };
            });
            let keysToCompare = ['impressions', 'clicks', 'conversions', 'ctr', 'cpa', 'cost'];

            let max = growthRates.reduce((max: any, item: any) => {
                let valuesToCompare = keysToCompare.map(key => item.data[key]).filter(value => value !== null);
                let maxItemValue = Math.max(...valuesToCompare);
                return maxItemValue > max.value ? { campaign_name: item.campaign_name, key: keysToCompare.find(key => item.data[key] === maxItemValue), value: maxItemValue } : max;
            }, { campaign_name: '', key: '', value: -Infinity });

            let min = growthRates.reduce((min: any, item: any) => {
                let valuesToCompare = keysToCompare.map(key => item.data[key]).filter(value => value !== null);
                let minItemValue = Math.min(...valuesToCompare);
                return minItemValue < min.value ? { campaign_name: item.campaign_name, key: keysToCompare.find(key => item.data[key] === minItemValue), value: minItemValue } : min;
            }, { campaign_name: '', key: '', value: Infinity });
            await fetchInsightsRising(max, min, growthRates);
            setMaxGrown(max);
            setMinGrown(min);
            // fetch audience information datas
            const impressionSums = getImpressionSumsByAgeRange(result.data.audience);

            setAudienceResult(getTopTwoImpressions(impressionSums));
            const impressions = getImpressionSumPerDate(result.data.audience)
            setDateValue(impressions);

            const maxImpression = Math.max(...impressions?.map(Number));
            setMaxPos(impressions.indexOf(maxImpression));

            
            const gender = sumAudienceGender(result?.data?.gender);
            setAudienceGender(gender);

            const keyMapping = {
                "10": "Male",
                "11": "Female",
                "20": "Unknown"
            };
            
            const maxCostMicrosKey = Object.entries(gender as Record<string, {cost_micros: number}>).reduce((maxKey, [key, value]) => {
                return value.cost_micros > (gender as Record<string, {cost_micros: number}>)[maxKey].cost_micros ? key : maxKey;
            }, Object.keys(gender)[0]);
            
            setTopGender(keyMapping[maxCostMicrosKey as keyof typeof keyMapping]);

            setloading(false);
        } catch (e) {
            console.log(e)
            setloading(false);
        }
    }

    const onChange = async (days: number) => {
        setloading(true);
        const result = await axios.post(`${SERVER_URL}/analytics/days`, {
            refresh_token: access_token,
            customerId,
            days
        });
        setPerform(result.data?.perform);
        setPerforms(calculateTotals(result.data?.perform));
        await fetchInsights(calculateTotals(result.data?.perform));
        setloading(false);
    }
    function getImpressionSumPerDate(data: any) {
        const total = data.reduce((acc:any, curr:any) => {
            if (!acc[curr.date]) {
                acc[curr.date] = 0;
            }
    
            curr.age_ranges.forEach((range : any) => {
                acc[curr.date] += range.impression;
            });
    
            return acc;
        }, {});
        // const total =  data.map((item: any) => {
        //     const totalImpressions = item.age_ranges.reduce((sum: any, ageRange: { impression: any; }) => sum + ageRange.impression, 0);
        //     return totalImpressions;
        // });
        return Object.values(total);
        
    }
    const getImpressionSumsByAgeRange = (data: { date: string; age_ranges: { age_range: number; impression: number }[] }[]) => {
        const impressionsByAgeRange: { [key: number]: number } = {};

        data.forEach((item) => {
            item.age_ranges.forEach((range) => {
                impressionsByAgeRange[range.age_range] = (impressionsByAgeRange[range.age_range] || 0) + range.impression;
            });
        });

        return impressionsByAgeRange;
    };
    const getTopTwoImpressions = (impressions: { [key: number]: number }): TopImpressionsResult => {
        const impressionsArray = Object.entries(impressions)
            .filter(([ageRangeCode]) => ageRangeCode !== "503999") // Skip the "Unknown" age range
            .map(([ageRangeCode, impression]) => ({
                ageRange: ageRangeMapping[Number(ageRangeCode)] || "Unknown",
                impression: impression,
            }));

        // Sort the array by impressions in descending order
        impressionsArray.sort((a, b) => b.impression - a.impression);

        const top1 = impressionsArray[0];
        const top2 = impressionsArray[1];
        const totalImpressions = impressionsArray.reduce((sum, item) => sum + item.impression, 0);

        const top1Percent = ((top1.impression / totalImpressions) * 100).toFixed(2);
        const top2Percent = ((top2.impression / totalImpressions) * 100).toFixed(2);

        return {
            top1: { ageRange: top1.ageRange, impression: top1.impression, percent: top1Percent },
            top2: { ageRange: top2.ageRange, impression: top2.impression, percent: top2Percent },
            totalImpressions,
        };
    };


    return (
        <div>
            {/** Title Part */}
            <div className="flex flex-row items-center my-[32px]">
                <label
                    className="title-f40-700 w-[30%]"
                    style={{ color: themeContext?.theme.titleColor }}
                >
                    Analytics
                </label>
            </div>
            {/** Content Part */}

            <div className="flex flex-col md:grid md:grid-cols-12 gap-[16px]">
                <div
                    className="col-span-12 rounded-[8px] p-[24px] flex flex-col gap-[16px] relative"
                    style={{ backgroundColor: themeContext?.theme.foreground }}
                >
                    <div className="flex md:flex-row flex-col justify-between gap-2">
                        <label className="font-h3-700">Performance Overview <Tooltip title="View aggregated metrics like impressions, clicks, CTR, conversions, CPA, and spend across all campaigns during the selected time period." enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
                            <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
                        </Tooltip></label>
                        <select
                            className="px-[12px] rounded-[50px] Button md:w-[20%] w-full md:py-0 py-2"
                            style={{
                                backgroundColor: themeContext?.theme.buttonBackground,
                                color: themeContext?.theme.color,
                                border: `1px solid ${themeContext?.theme.activeButtonBackground}`,
                            }}
                            onChange={(e) => {
                                if (e.target.value === 'last7days') {
                                    onChange(7);
                                }
                                else if (e.target.value === 'last30days') {
                                    onChange(30);
                                } else if (e.target.value === 'currentYear') {
                                    onChange(365);
                                }
                            }}
                        >
                            <option value="last7days">7 days</option>
                            <option value="last30days">30 days</option>
                            <option value="currentYear">The current year</option>
                        </select>
                    </div>
                    <div className="grid grid-cols-6 gap-[8px]">
                        <div className="md:col-span-2 col-span-3">
                            <ItemInfoList icon={<SendOutlinedIcon style={{ fontSize: '8px' }} />} title="Total Clicks" content={performs?.clicks ? performs.clicks?.toLocaleString() : '0'} />
                        </div>
                        <div className="md:col-span-2 col-span-3">
                            <ItemInfoList icon={<ShoppingCartOutlinedIcon style={{ fontSize: '8px' }} />} title="Total Conversions" content={performs?.conversions ? performs.conversions?.toString() : '0'} />
                        </div>
                        <div className="md:col-span-2 col-span-3">
                            <ItemInfoList icon={<VisibilityOutlinedIcon style={{ fontSize: '8px' }} />} title="Total Impressions" content={performs?.impressions ? performs.impressions?.toLocaleString() : '0'} />
                        </div>
                        <div className="md:col-span-2 col-span-3">
                            <ItemInfoList icon={<ComputerOutlinedIcon style={{ fontSize: '8px' }} />} title="Overall CTR" content={`${performs.ctr ? (Math.floor(performs?.ctr * 10000) / 100).toString() : '0'} %`} />
                        </div>
                        <div className="md:col-span-2 col-span-3">
                            <ItemInfoList icon={<DiscountOutlinedIcon style={{ fontSize: '8px' }} />} title="Average CPA" content={`$${performs?.cpa ? performs.cpa.toString() : '0'}`} />
                        </div>
                        <div className="md:col-span-2 col-span-3">
                            <ItemInfoList icon={<AttachMoneyIcon style={{ fontSize: '10px' }} />} title="Spend" content={`$${performs?.spend ? performs?.spend.toString() : '0'}`} />
                        </div>
                    </div>
                    <PerformingTable perform={perform} />
                    <InfoAlert str={insightData? insightData : 'loading...'} />
                    {/* <div className="mb-[24px]">
                        <button className="px-[12px]  rounded-[50px] font-button-700 w-fit"
                            style={{
                                backgroundColor: themeContext?.theme.activeButtonBackground,
                                color: themeContext?.theme.activeColor,
                                position: 'absolute',
                                right: '16px',
                                bottom: '16px'
                            }} >
                            View Detailed Analytics
                        </button>
                    </div> */}
                </div>
                {/* <div
                    className="col-span-5 rounded-[8px] p-[24px] flex flex-col gap-[16px]"
                    style={{ backgroundColor: themeContext?.theme.foreground, position: 'relative' }}
                >
                    <div className="flex flex-row justify-between ">
                        <label className="font-h3-700">Top Keyword Opportunities <Tooltip title="Identify high-potential keywords based on search volume, competition, and suggested bids to improve campaign targeting." enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
                            <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
                        </Tooltip></label>
                    </div>
                    <KeyTable keyword={keywords[0]} limit={3} />
                    <InfoAlert str="High demand for beginner-level stock market content. Leverage low-competition keywords to capture new investors." />
                    <div className="mb-[24px]">
                        <button className="px-[12px]  rounded-[50px] font-button-700 w-fit"
                            style={{
                                backgroundColor: themeContext?.theme.activeButtonBackground,
                                color: themeContext?.theme.activeColor,
                                position: 'absolute',
                                right: '16px',
                                bottom: '16px'
                            }} onClick={() => handleExploreClick()}>
                            Explore Keywords
                        </button>
                    </div>

                    <Modal
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-keywords-title"
                        aria-describedby="modal-keywords-description"

                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start', // Align items to the start
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '70%',
                                bgcolor: 'background.paper',
                                borderRadius: '10px',
                                boxShadow: 24,
                                p: 4,
                                maxHeight: '80%', // Limit height
                                overflowY: 'auto',
                                minHeight: '400px', // Ensure minimum height for visibility
                            }}
                        >
                            <Typography id="modal-keywords-title"
                                variant="h6"
                                component="h2"
                                sx={{ textAlign: 'center', marginBottom: 3 }}>
                                Explore All Keywords
                            </Typography>
                            <Button
                                onClick={handleCloseModal}
                                sx={{ position: 'absolute', top: '10px', right: '10px' }}
                            >
                                Close
                            </Button>
                            <KeyTable keyword={keyword} />
                        </Box>
                    </Modal>

                    <Modal
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-keywords-title"
                        aria-describedby="modal-keywords-description"

                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start', // Align items to the start
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '70%',
                                bgcolor: 'background.paper',
                                borderRadius: '10px',
                                boxShadow: 24,
                                p: 4,
                                maxHeight: '80%', // Limit height
                                overflowY: 'auto',
                                minHeight: '400px', // Ensure minimum height for visibility
                            }}
                        >
                            <Typography id="modal-keywords-title"
                                variant="h6"
                                component="h2"
                                sx={{ textAlign: 'center', marginBottom: 3 }}>
                                Explore All Keywords
                            </Typography>
                            <Button
                                onClick={handleCloseModal}
                                sx={{ position: 'absolute', top: '10px', right: '10px' }}
                            >
                                Close
                            </Button>
                            <KeyTable keyword={keywords[0]} />
                        </Box>
                    </Modal>
                </div> */}


                <div
                    className="col-span-6 rounded-[8px] p-[24px] flex flex-col gap-[8px]"
                    style={{ backgroundColor: themeContext?.theme.foreground, position: 'relative' }}
                >
                    <div className="flex flex-row justify-between ">
                        <label className="font-h3-700">Rising Trends and Underperformers <Tooltip title="Highlight campaigns with significant performance changes, including growth trends and underperformers, with AI-suggested actions." enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
                            <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
                        </Tooltip></label>
                    </div>
                    <div >
                        <div className="rounded-[2px] flex flex-col gap-[8px] mb-[16px]">
                            <p className="text-[#666666] font-button">Trending Campaign</p>
                            <div className="font-button-700">{maxGrown ? maxGrown.campaign_name : ''}</div>
                            
                            <div className="flex flex-row gap-[8px] w-full">
                                <ItemList icon={<LeaderboardIcon style={{ fontSize: "12px" }} />} title="Growth Rate" content={`+${maxGrown? maxGrown?.value?.toFixed(2): '0'}% in the past 30 days`} />
                                <ItemList icon={<VisibilityOutlinedIcon style={{ fontSize: "12px" }} />} title="Key Metric Impacted" content={maxGrown ? maxGrown.key : ''} />
                            </div>

                            <Divider style={{ height: '2px', color: '#000' }} />
                            <p className="text-[#666666] font-button">Underperforming Campaign</p>
                            <div className="font-button-700">{minGrown ? minGrown.campaign_name : ''}</div>
                            
                            <div className="flex flex-row gap-[8px] w-full">
                                <ItemListNegative icon={<LeaderboardIcon style={{ fontSize: "12px" }} />} title="Growth Rate" content={`${minGrown? minGrown?.value?.toFixed(2) : '0'}% in the past 30 days`} />
                                <ItemList icon={<VisibilityOutlinedIcon style={{ fontSize: "12px" }} />} title="Key Metrics impacted" content={minGrown ? minGrown.key : ''} />
                            </div>
                        </div>
                        {/* <button className="px-[12px]  rounded-[50px] font-button-700 w-fit"
                            style={{
                                backgroundColor: themeContext?.theme.activeButtonBackground,
                                color: themeContext?.theme.activeColor,
                                position: 'absolute',
                                right: '16px',
                                bottom: '16px'
                            }} >
                            Benchmark Now
                        </button> */}
                    </div>
                    <InfoAlert str={insightRising? insightRising : 'loading...'} />
                </div>

                <div
                    className="col-span-6 rounded-[8px] p-[24px] flex flex-col gap-[16px]"
                    style={{ backgroundColor: themeContext?.theme.foreground, position: 'relative' }}
                >
                    <div className="flex flex-row justify-between mb-[30px]">
                        <label className="font-h3-700">Audience Insights 
                            <Tooltip title="Analyze audience performance by age, gender, location, and affinity segments. Discover the best days and times to engage." enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
                            <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
                        </Tooltip>
                        </label>
                    </div>

                    <div className="mb-[24px] flex md:flex-row flex-col gap-[16px]">

                        <div className="rounded-[2px] flex flex-col gap-[13px] mb-[16px] md:w-[55%] md:shrink-0">
                            {/* <div className="font-button-700">Top Demographics</div>
                            <div className="flex flex-row gap-[8px]">
                                <ArrowOutwardOutlinedIcon style={{ fontSize: '16px', color: '#4152EC' }} />
                                <div className="font-b5-500 text-left">Your audience is most engaged with video content during morning commutes and evening hours. Capitalize on this by scheduling posts during these times.</div>
                            </div> */}
                            <div className="flex flex-row gap-[8px] w-full">
                                <ItemList icon={<PeopleAltOutlinedIcon style={{ fontSize: "12px" }} />} title={`Age ${audienceResult?.top1.ageRange}`} content={`${audienceResult?.top1.percent}% of total audience`} />
                                <ItemList icon={<PeopleAltOutlinedIcon style={{ fontSize: "12px" }} />} title={`Age ${audienceResult?.top2.ageRange}`} content={`${audienceResult?.top2.percent}% of total audience`} />
                            </div>
                            <div className="flex flex-row gap-[8px] w-full">
                                <ItemList icon={<PeopleAltOutlinedIcon style={{ fontSize: "12px" }} />} title={`Male`} content={`${audienceGender && audienceGender['10']?.cost_micros.toFixed(1)}% of total audience`} />
                                <ItemList icon={<PeopleAltOutlinedIcon style={{ fontSize: "12px" }} />} title={`Female `} content={`${audienceGender && audienceGender['11']?.cost_micros.toFixed(1)}% of total audience`} />
                                <ItemList icon={<PeopleAltOutlinedIcon style={{ fontSize: "12px" }} />} title={`Unkn.. `} content={`${audienceGender && audienceGender['20']?.cost_micros.toFixed(1)}% of total audience`} />

                            </div>
                            {/* <ItemList icon={<LocationOnOutlinedIcon style={{ fontSize: "12px" }} />} title="Top Locations" content="New York, San Francisco, London" /> */}
                            <InfoAlert str={`Your best audience are ${audienceResult?.top1.ageRange} ${topGender} , on ${days[maxPos]}`} />
                        </div>

                        <div className="shrink-0 md:w-[45%] flex flex-col gap-[8px] mb-4 md:mb-0 ">
                            <div className="flex flex-row justify-between p-[8px] rounded-[4px]" style={{ backgroundColor: themeContext?.theme.hoverBackground }}>
                                <p className="font-button-700">Last Week Impressions</p>
                                <div className="flex flex-row gap-[8px] items-center">
                                    <ArrowCircleUpOutlinedIcon style={{ fontSize: '16px', color: '#4152EC' }} />
                                    <p className="font-button-700">{days[maxPos]}</p>
                                    {/* <ArrowCircleUpOutlinedIcon style={{ fontSize: '16px', color: '#4152EC' }} />
                                    <p className="font-button-700">9 am</p> */}
                                </div>
                            </div>
                            <BarChartComponent dateLabel={dateData.label} dateValue={dateValue} />
                        </div>
                    </div>
                    {/* <button className="px-[12px]  rounded-[50px] font-button-700 w-fit"
                        style={{
                            border: `1px solid ${themeContext?.theme.color}`,
                            color: themeContext?.theme.color,
                            position: 'absolute',
                            right: '176px',
                            bottom: '16px',
                            height: '28px'
                        }} >
                        View All Insights
                    </button>
                    <button className="px-[12px]  rounded-[50px] font-button-700 w-fit"
                        style={{
                            backgroundColor: themeContext?.theme.activeButtonBackground,
                            color: themeContext?.theme.activeColor,
                            position: 'absolute',
                            right: '16px',
                            bottom: '16px'
                        }} >
                        Optimize Engagement
                    </button> */}
                </div>


            </div>
        </div>
    );
};