import {
    useContext,
    useEffect,
    useState,
} from "react";
import { ThemeContext } from "../../components/Theme/context";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { PerformingTable } from "./performingTable";
import { InfoAlert } from "../component/infoAlert";
import { ItemList } from "../component/itemList";
import { PersonCard } from "./personCard";
import { Tooltip } from "@mui/material";
import UserContext from "../../utils/userContext";
import { SERVER_URL } from "../../utils/constant";
import axios from "axios";
import { processCampaignData } from '../../utils/helper';
export const Strategies = () => {
    const themeContext = useContext(ThemeContext);
    const { setloading } = useContext(UserContext);
    const access_token = localStorage.getItem("access_token");
    const customerId = localStorage.getItem("customerId")?.replace(/-/g, '');
    const [perform, setPerform] = useState([]);
    const [topPerfom, setTopPerform] = useState<any>({});

    const initialze = async () => {
        setloading(true);
        try{
            const result = await axios.post(`${SERVER_URL}/strategies`, {
              refresh_token: access_token,
              customerId: customerId,
            });
            

            setPerform(processCampaignData(result.data));
            const datas = processCampaignData(result.data);
            datas.sort((a : any, b : any) => parseFloat(b.ctr) - parseFloat(a.ctr));
            console.log(datas)
            setTopPerform(datas[0]);
            setloading(false);
        } catch (e) {
            setloading(false);
            console.log(e);
        }
      
    }
    useEffect(() => {
        initialze();
      }, []);
    return (
        <div>
            {/** Title Part */}
            <div className="flex flex-row items-center my-[32px]">
                <label
                    className="title-f40-700 w-[30%]"
                    style={{ color: themeContext?.theme.titleColor }}
                >
                    Strategies
                </label>
            </div>
            {/** Content Part */}

            <div className="flex flex-col md:grid md:grid-cols-12 gap-[32px]" >
                <div
                    className="col-span-7 rounded-[8px] p-[24px] flex flex-col gap-[16px] relative"
                    style={{ backgroundColor: themeContext?.theme.foreground }}
                >
                    <div className="flex flex-row justify-between ">
                        <label className="font-h3-700">Explore High-Performing Strategies 
                            <Tooltip title="Discover the most effective strategies used in your campaigns, supported by performance data and AI recommendations for improvement." enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
                  <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
                </Tooltip></label>

                        {/* <button
                            className="px-[12px]  rounded-[50px] font-button-700"
                            style={{
                                backgroundColor: themeContext?.theme.activeButtonBackground,
                                color: themeContext?.theme.activeColor,
                            }}
                        >
                            30d
                        </button> */}
                    </div>
                    <PerformingTable perform = {perform} limit = {3}/>
                    <InfoAlert str="Educational content is performing exceptionally well; consider adding webinars to your crypto outreach strategy." />
                    <div  className="mb-[24px]">
                        <button className="px-[12px] rounded-[50px] font-button-700 w-fit"
                            style={{
                                backgroundColor: themeContext?.theme.activeButtonBackground,
                                color: themeContext?.theme.activeColor,
                                position:'absolute',
                                right:'16px',
                                bottom:'16px'
                            }} >
                            Explore Strategies
                        </button>
                    </div>
                </div>
                <div
                    className="col-span-5 rounded-[8px] p-[24px] flex flex-col gap-[16px] relative"
                    style={{ backgroundColor: themeContext?.theme.foreground }}
                >
                    <div className="flex flex-row justify-between relative">
                        <label className="font-h3-700">Blueprints for Success <Tooltip title="Access step-by-step guides for implementing AI-suggested strategies tailored to your campaign goals and audience." enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
                  <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
                </Tooltip></label>

                        {/* <button
                            className="px-[12px] rounded-[50px] font-button-700"
                            style={{
                                backgroundColor: themeContext?.theme.activeButtonBackground,
                                color: themeContext?.theme.activeColor,
                                
                            }}
                        >
                            30d
                        </button> */}
                    </div>
                    <div className="border-[#666666] border-[1px] rounded-[2px] p-[16px] flex flex-col gap-[13px]">
                        <div className="font-button-700 font-faded">Top Blueprint</div>
                        <div className="font-button-700">Investor Confidence Booster for Market Volatility</div>
                        <div className="flex flex-row gap-[8px]">
                            <ArrowOutwardOutlinedIcon style={{fontSize:'16px', color:'#4152EC'}}/>  
                            <div className="font-b5-500 text-left">Step-by-step guide on creating content that addresses market fears and promotes safe investment options.</div>
                        </div>
                        <div className="flex flex-row gap-[8px] w-full">
                            <ItemList icon={<ErrorOutlineIcon style={{fontSize:"12px"}}/>} title="Example Content Pieces" content="Blog posts, short videos, infographics." />
                            <ItemList icon={<ErrorOutlineIcon style={{fontSize:"12px"}}/>} title="Target Audience" content="Risk-averse investors, ages 35-55." />
                        </div>
                        <ItemList icon={<ErrorOutlineIcon style={{fontSize:"12px"}}/>} title="Predicted Impact" content="+12% increase in portfolio diversification sign-ups." />
                        
                    </div>
                    <div  className="mb-[24px]">
                        <button className="px-[12px] rounded-[50px] font-button-700 w-fit"
                            style={{
                                backgroundColor: themeContext?.theme.activeButtonBackground,
                                color: themeContext?.theme.activeColor,
                                position:'absolute',
                                right:'16px',
                                bottom:'16px'
                            }} >
                            Use Blueprint
                        </button>
                    </div>
                </div>
                

                <div
                    className="col-span-7 rounded-[8px] p-[24px] flex flex-col gap-[16px] relative"
                    style={{ backgroundColor: themeContext?.theme.foreground }}
                >
                    <div className="flex flex-row justify-between ">
                        <label className="font-h3-700">Dynamic Buyer Personas <Tooltip title="Understand your audience better with AI-generated buyer personas, including demographics, interests, and behavior insights." enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
                  <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
                </Tooltip></label>
                    </div>
                    <div className="flex md:flex-row flex-col gap-[8px]">
                        <PersonCard title="Persona 1" author="Cautious Carla – The Safe Investor" age={45} perference="REITs, low-risk stocks." top="LinkedIn, Email newsletters." tradit="Seeks stability, values long-term growth, responds well to educational content." strategies="Educational content, performance comparisons, safe investment showcases." />

                        <PersonCard title="Persona 2" author="Bold Brian – The Risk-Taker" age={30} perference="Cryptocurrencies, high-risk stocks." top="Twitter, Reddit, YouTube." tradit="Thrives on excitement, values high potential returns, influenced by peer endorsements." strategies="Influencer endorsements, performance highlights, exclusive investment tips." />
                    </div>
                    <div className="mb-[24px]">
                        <button className="px-[12px]  rounded-[50px] font-button-700 w-fit"
                            style={{
                                backgroundColor: themeContext?.theme.activeButtonBackground,
                                color: themeContext?.theme.activeColor,
                                position:'absolute',
                                right:'16px',
                                bottom:'16px'
                            }} >
                            View Personas
                        </button>
                    </div>
                </div>

                <div
                    className="col-span-5 rounded-[8px] p-[24px] flex flex-col gap-[16px] relative"
                    style={{ backgroundColor: themeContext?.theme.foreground }}
                >
                    <div className="flex flex-row justify-between ">
                        <label className="font-h3-700">Benchmark Against Competitors <Tooltip title="Compare your campaign performance with competitors. Analyze metrics such as CTR, conversions, and impression share." enterTouchDelay={0} leaveTouchDelay={5000} onClick={(event) => event.stopPropagation()}>
                  <InfoOutlinedIcon sx={{ width: 15, cursor: 'pointer' }} />
                </Tooltip></label>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <div className="flex flex-row gap-[8px]">
                            <div className="font-h5-700 self-center">WealthGrowth</div>
                           
                        </div>
                        <div className="flex flex-row font-left justify-between">
                            <div className="font-b5-500  flex flex-col gap-[8px]"><div>CTR</div>  <div>42%</div></div>
                            <div className="font-b5-500  flex flex-col gap-[8px]"><div>Conversion Rate </div> <div>15%</div> </div>
                            <div className="font-b5-500  flex flex-col gap-[8px]"><div>CAC</div> <div>$12</div></div>
                        </div>   
                        <InfoAlert str="WealthGrowth’s content-driven approach outperforms typical email campaigns by focusing on detailed investment insights."/>
                    </div>

                    <div className="flex flex-col gap-[8px]">
                        <p className="font-button text-[#666666]">YOUR TOP CAMPAIGN</p>
                        <div className="flex flex-row gap-[8px]">
                            <div className="font-h5-700 self-center">{topPerfom? topPerfom.strategyName : ''}</div>
                            
                        </div>
                        <div className="flex flex-row font-left justify-between">
                            <div className="font-b5-500 flex flex-col gap-[8px]"><div>CTR</div>  <div>{topPerfom ? topPerfom.ctr : '0'}%</div></div>
                            <div className="font-b5-500 flex flex-col gap-[8px]"><div>Conversion Rate</div> <div>{topPerfom ? topPerfom.conversionRate : '0'}%</div></div>
                            <div className="font-b5-500 flex flex-col gap-[8px]"><div>CAC  </div> <div>${topPerfom ? (topPerfom.cac) : '0'}</div> </div>
                        </div>   
                        <InfoAlert str="WealthGrowth’s content-driven approach outperforms typical email campaigns by focusing on detailed investment insights."/>
                    </div>
                    <div className="mb-[24px] mt-[24px]">
                        <button className="px-[12px]  rounded-[50px] font-button-700 w-fit"
                            style={{
                                backgroundColor: themeContext?.theme.activeButtonBackground,
                                color: themeContext?.theme.activeColor,
                                position:'absolute',
                                right:'16px',
                                bottom:'16px'
                            }} >
                            Benchmark Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};