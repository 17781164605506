import { useContext } from "react";
import { ThemeContext } from "../../components/Theme/context";
import { FireIcon, GoogleIcon } from "../component/icons";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const isValidCampaignIdeas = (data: any): data is CampaignIdea[] => {
    return Array.isArray(data) && data.every(item =>
      item && typeof item === 'object' &&
      typeof item.channel === 'string' &&
      typeof item.headline === 'string' &&
      typeof item.targetAudience === 'string' &&
      typeof item.impactForIndustry === 'string'
    );
  };

export interface CampaignIdea {
    channel: string;
    headline: string;
    targetAudience: string;
    impactForIndustry: string;
    currentDate: string;
}

interface CampaignIdeasTableProps {
    data: Array<CampaignIdea>;
    info: string;
}

export const CampaignIdeasTable: React.FC<CampaignIdeasTableProps> = ({ data, info }) => {
    const themeContext = useContext(ThemeContext);
    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text).then(
            () => { },
            (err) => {
                console.error("Unexpected Error: ", err);
            }
        );
    };

    return (
        <div>
            {data.length === 0 ? (
                <div className="text-center py-4 text-gray-500">
                    <p>{ info || "No campaign ideas available at the moment." }</p>
                </div>
            ) : (
                <table className="text-left font-b4-500 md:overflow-auto overflow-scroll block md:table">
                    <thead>
                        <tr>
                            <th>Channel</th>
                            <th>Headline</th>
                            <th>Target Audience</th>
                            <th>Impact for Industry</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.channel == "google-ads" && <GoogleIcon />}</td>
                                <td
                                    style={{
                                        wordWrap: "break-word",
                                        overflowWrap: "break-word",
                                        whiteSpace: "normal",
                                        maxWidth: "200px",
                                    }}
                                >
                                    <div className="flex items-center gap-x-[8px]">
                                        <button
                                            onClick={() => handleCopy(item.headline)}
                                            className="ml-2 text-blue-500"
                                            title="Copy headline"
                                        >
                                            <ContentCopyIcon sx={{ fontSize: 12 }} />
                                        </button>
                                        <span>{item.headline}</span>
                                    </div>
                                </td>
                                <td
                                    style={{
                                        wordWrap: "break-word",
                                        overflowWrap: "break-word",
                                        whiteSpace: "normal",
                                        maxWidth: "200px",
                                    }}
                                >
                                    {item.targetAudience}
                                </td>
                                <td
                                    style={{
                                        wordWrap: "break-word",
                                        overflowWrap: "break-word",
                                        whiteSpace: "normal",
                                        maxWidth: "200px",
                                        textAlign: "left",
                                    }}
                                >
                                    <div
                                        className="flex items-center gap-x-[4px] cursor-pointer"
                                        style={{
                                            backgroundColor: themeContext?.theme.foreground,
                                            color: themeContext?.theme.color,
                                        }}
                                    >
                                        <FireIcon />
                                        <span>{item.impactForIndustry}</span>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>)}
        </div>
    );
};
