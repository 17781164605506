import React, { CSSProperties, useContext, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import {router} from './routes/index';
import { Toaster } from 'react-hot-toast';
import UserContext from './utils/userContext';
import ScaleLoader from "react-spinners/ScaleLoader";
function App() {
  const {  loading } = useContext(UserContext);
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  return (
    <>
      <RouterProvider router={router} />
      <Toaster position="top-right" toastOptions={{
          style: {
            fontSize: '14px'
          },
        }} />
        {loading && (
        <div className='loader-overlay'>
          <ScaleLoader
            color='#F9BC04'
            loading={loading}
            cssOverride={override}
            width={5}
            height={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          </div>
      )}
    </>
  );
}

export default App;
