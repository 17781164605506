
import { useEffect, useState } from 'react';
import { GoogleIcon } from '../component/icons';
import { sumFieldsByCampaign } from '../../utils/helper';
export const PerformingTable = ({ perform }: { perform: any }) => {
    const [performData, setPerformData] = useState<any>([]);
    useEffect(() => {
        setPerformData(sumFieldsByCampaign(perform));
    }, [perform])

    return (
        <table className="text-left font-b4-500 md:overflow-auto overflow-scroll block md:table">
            <thead>
                <tr>
                    <th>Channel</th>
                    <th style={{width:'40%'}}>Champaign</th>
                    <th>Impressions</th>
                    <th>Clicks</th>
                    <th>Conversions</th>
                    <th>CTR</th>
                    <th>CPA</th>
                </tr>
            </thead>
            <tbody>
                {
                    performData.length > 0 ? performData?.map((item: any) => (
                        <tr>
                            <td><GoogleIcon /> </td>
                            <td>{item?.campaign_name}</td>
                            <td>{item?.data.impressions}</td>
                            <td>{item?.data.clicks}</td>
                            <td>{item?.data.conversions}</td>
                            <td>{`${((item?.data.ctr ).toFixed(2))}%`}</td>
                            <td>${item?.data.cpa}</td>
                        </tr>
                    )) : (<></>)
                }
                {/* <tr>
                    <td><FBIcon/>  </td>
                    <td>2,500,000</td>
                    <td>140,000</td>
                    <td>9,500</td>
                    <td>5.6%</td>
                    <td>$12.00</td>
                </tr>
                <tr>
                    <td><LinkedInIcon style={{color:'#0A66C2'}}/> </td>
                    <td>2,500,000</td>
                    <td>140,000</td>
                    <td>7,000</td>
                    <td>5.6%</td>
                    <td>$15.00</td>
                </tr>
             */}
            </tbody>
        </table>
    );
};